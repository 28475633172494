import React, { useState } from 'react';
import './Contacts.css';

function Contacts() {
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    email: '',
    message: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, lastName, email, message } = formData;

    // Basic validation
    if (!name || !lastName || !email || !message) {
      setError('All fields are required.');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Invalid email address.');
      return;
    }

    setError('');
    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Something went wrong. Please try again.');
      }

      setSuccess('Message sent successfully!');
      setFormData({ name: '', lastName: '', email: '', message: '' });
    } catch (error) {
      setError('Failed to send the message. Please try again later.');
    }
  };

  return (
    <div className="contacts-page">
      <div className="contacts-container">
        {/* Left Section */}
        <div className="left-section">
          {/* Logo */}
          <div className="logo-container">
            <img src="/images/ramelogo.jpg" alt="Rame Store Logo" className="logo-img" />
          </div>

          {/* Box 1: Questions */}
          <div className="questions-box">
            <h2>Got questions?<br />Need help? Let’s chat!</h2>
            <ul>
              <li>. Product recommendations 🎧</li>
              <li>. Order questions 📦</li>
              <li>. General inquiries ✉️</li>
            </ul>
          </div>

          {/* Box 2: How to Reach Us */}
          <div className="contact-box">
            <h3>How to Reach Us:</h3>
            <p>📧 Email: contact@ramestore.com</p>
            <p>📞 Call : +216 29 050 348</p>
            <p>💬 DM   : Slide into our Instagram @the_ramestore</p>
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
            <input
              type="email"
              name="email"
              className="full-width-input"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your message ..."
            ></textarea>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
